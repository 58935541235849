import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { DeepExtractType } from 'ts-deep-extract-types';

import Layout from '../components/Layout';
import Footer from '../components/Footer';
import { Container, Section } from '../components/generic/View';
import { Heading2 } from '../components/generic/Text';
import Content from '../components/RichContent';

type Props = DeepExtractType<
  GatsbyTypes.PrivacyPageTemplateQuery,
  ['markdownRemark', 'frontmatter']
>;

export const PrivacyPageTemplate: FC<Props> = ({
  privacy_policy_section,
  footer_section
}) => {
  return (
    <>
      <Section withGradient>
        <Container>
          <Heading2 borderBottom className="mb-5 md:mb-12.5">
            {privacy_policy_section?.heading}
          </Heading2>

          <Content markdown={privacy_policy_section?.rich_text} />
        </Container>
      </Section>
      <Footer richText={footer_section?.rich_text || ''} />
    </>
  );
};

const PrivacyPage: FC<{ data: GatsbyTypes.PrivacyPageTemplateQuery }> = ({
  data
}) => (
  <Layout
    pageTitle={data.markdownRemark?.frontmatter?.pageTitle || ''}
    metaTagDescription={
      data.markdownRemark?.frontmatter?.meta_tag_description || ''
    }
  >
    <PrivacyPageTemplate
      pageTitle={data.markdownRemark?.frontmatter?.pageTitle}
      meta_tag_description={
        data.markdownRemark?.frontmatter?.meta_tag_description
      }
      privacy_policy_section={
        data.markdownRemark?.frontmatter?.privacy_policy_section
      }
      footer_section={data.markdownRemark?.frontmatter?.footer_section}
    />
  </Layout>
);

export default PrivacyPage;

export const pageQuery = graphql`
  query PrivacyPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "privacy-page" } }) {
      frontmatter {
        pageTitle
        meta_tag_description
        privacy_policy_section {
          heading
          rich_text
        }
        footer_section {
          rich_text
        }
      }
    }
  }
`;
